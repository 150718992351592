import React, { memo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { AppRoute, movingRoutes } from 'common/routing/AppRoute';
import { ContactInformationForm } from 'common/app/contactInformationForm/ContactInformationForm';
import { ContactInformationFormValues } from 'common/app/contactInformationForm/ContactInformationForm.types';
import { CategoryIdEnum } from 'common/api/types';
import { useMovingContext } from 'aMove/hooks/useMoving/useMoving';
import { movingActions } from 'aMove/context/moving/MovingContextSlice';
import { useAccount } from 'common/hooks/useAccount/useAccount';
import { useFormState } from 'common/hooks/useFormState/useFormState';
import { useCheckPostalCode } from 'common/hooks/useCheckPostalCode/useCheckPostalCode';
import { AccountResponse } from 'common/api/actions/account/accountActions.types';

export const MovingStep5 = memo(() => {
  const history = useHistory();
  const [{ moving }, { movingDispatch }] = useMovingContext();
  const { createOrUpdateAccount, isCreatingOrUpdatingAccount, recreateAccountWithNewLocationId } = useAccount();
  const { account } = useFormState();
  const { checkPostalCode, isChecking } = useCheckPostalCode({
    category_id: CategoryIdEnum.move,
    need_postal_info: true,
  });

  const handleSubmit = useCallback(
    async (values: ContactInformationFormValues, isDirty: boolean) => {
      if (!isDirty) {
        history.push(movingRoutes.step1);
        return;
      }

      const { address, nearestLocationId } = await checkPostalCode(values.postal);

      if (address && nearestLocationId) {
        let accountRes: AccountResponse | undefined;
        const payload = {
          contactInformationData: values,
          billingAddress: address,
          categoryId: CategoryIdEnum.move,
        };

        if (account?.locationId && account.locationId !== nearestLocationId) {
          accountRes = await recreateAccountWithNewLocationId(payload, nearestLocationId);
        } else {
          accountRes = await createOrUpdateAccount(payload, nearestLocationId);
        }

        if (!accountRes) {
          return;
        }

        movingDispatch(movingActions.setStep5(values));
        history.push(movingRoutes.step1);
      }
    },
    [
      checkPostalCode,
      history,
      account?.locationId,
      movingDispatch,
      recreateAccountWithNewLocationId,
      createOrUpdateAccount,
    ],
  );

  return (
    <ContactInformationForm
      loading={isCreatingOrUpdatingAccount || isChecking}
      onSubmit={handleSubmit}
      title="1. Contact information"
      onBackClick={() => history.push(AppRoute.home)}
      defaultValues={{
        firstName: moving?.step5?.firstName || account?.firstName || '',
        lastName: moving?.step5?.lastName || account?.lastName || '',
        email: moving?.step5?.email || account?.email || '',
        phone: moving?.step5?.phone || account?.phone || '',
        postal: moving?.step5?.postal || account?.postal,
        companyName: moving?.step5?.companyName || '',
        orderNumber: moving?.step5?.orderNumber || '',
        specialInstructions: moving?.step5?.specialInstructions || '',
        additionalNotes: moving?.step5?.additionalNotes || '',
        locationOfJunk: moving?.step5?.locationOfJunk || '',
      }}
      hasPrefilledData={!!moving?.step5}
      tipBoxData={{
        icon: 'threeStars',
        text: (
          <>
            100,000+ 5-Star Reviews <br /> We always strive to deliver 100% client satisfaction with every job.
          </>
        ),
      }}
    />
  );
});
