import { ReadyForRequestProcessingEnum } from '../formContext/FormContext.types';
import { initialFormState } from '../formStorage/FormStorage';

import {
  FormReducerType,
  RESET_FORM,
  SET_READY_FOR_PROCESSING,
  SET_BOOKING_DONE_AND_CONTACT,
  SET_GLOBAL_DATA,
  SET_CALL_ME_NOW,
  SET_PARTNER_DATA,
  SET_ACCOUNT_DATA,
} from './formReducer.types';

export const formReducer: FormReducerType = (state, action) => {
  switch (action.type) {
    case SET_GLOBAL_DATA:
      return {
        ...state,
        ...action.payload,
      };

    case RESET_FORM:
      return {
        ...initialFormState,
        isPartner: state.isPartner,
        partnerData: state.partnerData,
        token: state.token,
        accountId: state.accountId,
        account: state.account,
      };

    case SET_READY_FOR_PROCESSING:
      return {
        ...state,
        readyForRequestProcessing: action.payload.readyForRequestProcessing,
      };

    case SET_CALL_ME_NOW:
      return {
        ...state,
        contactData: action.payload,
        readyForRequestProcessing: ReadyForRequestProcessingEnum.CallMeNow,
        bookingDone: true,
      };

    case SET_BOOKING_DONE_AND_CONTACT:
      return {
        ...state,
        contactData: action.payload,
        bookingDone: true,
      };

    case SET_PARTNER_DATA:
      return {
        ...state,
        partnerData: action.payload,
        isPartner: true,
      };

    case SET_ACCOUNT_DATA:
      return {
        ...state,
        account: action.payload,
      };

    default:
      throw new Error(`Unhandled action type in FormReducer: ${JSON.stringify(action)}`);
  }
};
