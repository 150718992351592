import { LongWalksEnum } from 'common/api/types';
import { TruckParkingSelectEnum } from 'aMove/app/moving/MovingData';
import { Schedule } from 'common/api/actions/booking/bookingActions.types';

export const formatPhoneForApi = (phone: string) => {
  // Remove all non-digit characters
  let cleaned = phone.replace(/\D/g, '');

  // Remove the leading '1' if it's a US country code
  if (cleaned.startsWith('1') && cleaned.length === 11) {
    cleaned = cleaned.substring(1);
  }

  return cleaned;
};

// id is values from truckParkingSelect (in MovingData.tsx)
export const mapTruckParkingToLongWalks = (id: TruckParkingSelectEnum) => {
  switch (id) {
    case TruckParkingSelectEnum.Driveway:
      return LongWalksEnum.zero;
    case TruckParkingSelectEnum.StreetCloseWalk:
      return LongWalksEnum.zero;
    case TruckParkingSelectEnum.StreetSomewhatWalk:
      return LongWalksEnum.one;
    case TruckParkingSelectEnum.ParkingCloseWalk:
      return LongWalksEnum.one;
    case TruckParkingSelectEnum.ParkingSomewhatWalk:
      return LongWalksEnum.two;
    case TruckParkingSelectEnum.ParkingFarWalk:
      return LongWalksEnum.two;
  }
};

export const applyPhoneMask = (number: string) => {
  const numberWithMask = number.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  return numberWithMask;
};

export const isSameSchedule = (
  schedule1: Schedule & { resourceId: number },
  schedule2: Schedule & { resourceId: number },
) => {
  return (
    schedule1.start === schedule2.start &&
    schedule1.end === schedule2.end &&
    schedule1.resourceId === schedule2.resourceId
  );
};
