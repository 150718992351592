import { useMutation } from 'react-fetching-library';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { checkPostalCodeAction, getPostalInfoAction } from 'common/api/actions/booking/bookingActions';
import { CategoryIdEnum } from 'common/api/types';
import { junkRemovalRoutes, laborRoutes, movingRoutes } from 'common/routing/AppRoute';
import { findNearestLocationId } from 'common/utils/findNearestLocationId';
import { useFormDispatch } from '../useFormDispatch/useFormDispatch';
import { setGlobalData } from 'common/context/form/formActionCreators/formActionCreators';
import { BillingAddress } from 'common/api/actions/account/accountActions.types';

const unservicedRouteForTypeMap: Record<CategoryIdEnum, string> = {
  [CategoryIdEnum.junk]: junkRemovalRoutes.unserviced,
  [CategoryIdEnum.move]: movingRoutes.unserviced,
  [CategoryIdEnum.labor]: laborRoutes.unserviced,
};

export const useCheckPostalCode = ({
  category_id,
  need_postal_info,
}: {
  category_id: CategoryIdEnum;
  need_postal_info?: boolean;
}) => {
  const history = useHistory();
  const dispatch = useFormDispatch();
  const { mutate: checkPostalCodeRequest, loading: isChecking } = useMutation(checkPostalCodeAction);
  const { mutate: getPostalInfoRequest, loading: isGettingPostalInfo } = useMutation(getPostalInfoAction);

  const checkPostalCode = useCallback(
    async (postal: string, job_category_id: CategoryIdEnum = category_id) => {
      const { payload } = await checkPostalCodeRequest({ postal, job_category_id });

      if (!payload?.locations?.length) {
        history.push(unservicedRouteForTypeMap[job_category_id]);
        return Promise.reject();
      }

      const nearestLocationId = findNearestLocationId(payload?.locations);
      const zoneId = payload.locations[0].zone.id;
      let address: BillingAddress | undefined = undefined;

      dispatch(setGlobalData({ locationId: nearestLocationId, zoneId }));

      if (need_postal_info) {
        const { payload: postalInfoPayload } = await getPostalInfoRequest({ postal });

        if (!postalInfoPayload?.postal?.length) {
          history.push(unservicedRouteForTypeMap[job_category_id]);
          return Promise.reject();
        }

        address = {
          city: postalInfoPayload.postal[0].city,
          country: postalInfoPayload.postal[0].country,
          postal,
          state: postalInfoPayload.postal[0].state,
        };
      }

      return Promise.resolve({ address, nearestLocationId, zoneId });
    },
    [category_id, checkPostalCodeRequest, dispatch, need_postal_info, history, getPostalInfoRequest],
  );

  return { checkPostalCode, isChecking: isChecking || isGettingPostalInfo };
};
