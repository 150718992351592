import React, { useCallback } from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import { movingRoutes } from 'bMove/routing/AppRoute';
import { CategoryIdEnum } from 'common/api/types';
import { MovingFormStep1Step2 } from 'bMove/app/moving/movingFormStep1Step2/MovingFormStep1Step2';
import { MovingStep1Step2FormValues } from 'bMove/app/moving/movingFormStep1Step2/MovingFormStep1Step2.types';
import { useMovingContext } from 'bMove/hooks/useMoving/useMoving';
import { movingActions } from 'bMove/context/moving/MovingContextSlice';
import { useComparePostalCode } from 'common/hooks/useComparePostalCode/useComparePostalCode';

export const MovingStep1 = () => {
  const history = useHistory();
  const [{ moving }, { movingDispatch }] = useMovingContext();

  const handleSubmit = useCallback(
    async (values: MovingStep1Step2FormValues, isDirty) => {
      if (!isDirty) history.push(movingRoutes.step2);
      const { apartment, ...restValues } = values;

      const addressForApi = {
        ...restValues.fullAddress,
        address2: apartment,
      };

      movingDispatch(
        movingActions.setStep1({
          ...restValues,
          rooms: restValues.rooms as string,
          fullAddress: addressForApi,
        }),
      );

      history.push(movingRoutes.step2);
    },
    [movingDispatch, history],
  );

  const { comparePostal } = useComparePostalCode({
    categoryId: CategoryIdEnum.move,
    postal: moving.step5?.postal,
    onFail: (newPostal: string) => {
      if (moving?.step5) {
        movingDispatch(
          movingActions.setStep5({
            ...moving.step5,
            postal: newPostal,
          }),
        );
        history.push(movingRoutes.step5);
      }
    },
  });

  if (!moving?.step5) {
    return <Redirect to={movingRoutes.step5} />;
  }

  return (
    <MovingFormStep1Step2
      withRooms
      title="2. Moving from"
      onSubmit={handleSubmit}
      onBackClick={() => history.push(movingRoutes.step5)}
      defaultValues={{
        shortAddress: moving?.step1?.shortAddress || '',
        fullAddress: moving?.step1?.fullAddress || null,
        apartment: moving?.step1?.fullAddress.address2 || '',
        typeOfDwelling: moving?.step1?.typeOfDwelling ?? null,
        floor: moving?.step1?.floor ?? null,
        accessType: moving?.step1?.accessType ?? null,
        truckParking: moving?.step1?.truckParking ?? null,
        rooms: moving?.step1?.rooms || null,
      }}
      tipBoxData={{
        icon: 'h',
        text: (
          <>
            Did you know? HUNKS stands for <strong>H</strong>onest, <strong>U</strong>niformed, <strong>N</strong>ice,{' '}
            <strong>K</strong>nowledgeable <strong>S</strong>ervice – our promise to you!
          </>
        ),
      }}
      comparePostal={comparePostal}
    />
  );
};
