import { useCallback } from 'react';
import { useConfirmationDialog } from '@chhjpackages/components';

import { CategoryIdEnum } from 'common/api/types';
import { useCheckPostalCode } from '../useCheckPostalCode/useCheckPostalCode';
import { useFormState } from '../useFormState/useFormState';

export const useComparePostalCode = ({
  categoryId,
  postal,
  onFail,
}: {
  categoryId: CategoryIdEnum;
  postal?: string;
  onFail: (postal: string) => void;
}) => {
  const { locationId, zoneId } = useFormState();

  const { openConfirmation, closeConfirmation } = useConfirmationDialog({
    title: 'zip/postal code mismatch',
    message:
      'The zip/postal code entered in this step doesn’t match the code previously given.  Let’s make sure your zip/postal code is still serviced by entering that code in step 1.',
    confirmButtonText: 'Reenter zip/postal code',
    hideCancelButton: true,
    hideDialogCloseButton: true,
  });

  const { checkPostalCode, isChecking } = useCheckPostalCode({
    category_id: categoryId,
  });

  const comparePostal = useCallback(
    async (newPostal: string) => {
      if (newPostal && !isChecking) {
        const { nearestLocationId, zoneId: newZoneId } = await checkPostalCode(newPostal);

        if (!(locationId === nearestLocationId && zoneId === newZoneId)) {
          openConfirmation(() => {
            onFail(newPostal);
            closeConfirmation();
          });
        }
      }
    },
    [checkPostalCode, closeConfirmation, isChecking, locationId, onFail, openConfirmation, zoneId],
  );

  return { comparePostal };
};
