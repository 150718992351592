import React, { useCallback, useMemo } from 'react';
import { Typography } from '@material-ui/core';
import { NavLink, useHistory } from 'react-router-dom';
import clsx from 'clsx';

import { CheckIcon } from 'common/assets/icons/CheckIcon';
import { useFormState } from 'common/hooks/useFormState/useFormState';

import { AsideNavbarItemProps } from './AsideNavbarItem.types';
import { useStyles } from './AsideNavbarItem.styles';

export const AsideNavbarItem = ({ index, item, isMobile }: AsideNavbarItemProps) => {
  const styles = useStyles();
  const { location } = useHistory();
  const { isCallRequired } = useFormState();
  const { route, title, isDone, isOptional, isDisabled } = item;

  const isActive = useMemo(() => location.pathname === route, [location.pathname, route]);
  const isChecked = useMemo(() => !isActive && isDone && !isDisabled, [isActive, isDone, isDisabled]);
  const isOptionalGreyOut = useMemo(() => !!isOptional && isCallRequired !== false, [isOptional, isCallRequired]);

  const handleClick = useCallback(
    (e) => {
      if (isDisabled && !isActive) {
        e.preventDefault();
      }
    },
    [isActive, isDisabled],
  );

  // do not display current active item on mobiles
  if (isMobile && isActive) return null;
  return (
    <NavLink
      to={route}
      onClick={handleClick}
      className={clsx([
        !isDisabled && !isActive && styles.cursor,
        isChecked && styles.checkedItem,
        isActive && styles.activeItem,
        styles.item,
      ])}
    >
      {isChecked ? (
        <CheckIcon className={styles.icon} />
      ) : (
        <Typography
          variant="h6"
          color="inherit"
          className={clsx(styles.index, isActive && styles.activeIndex, isOptionalGreyOut && styles.textDisabled)}
        >
          {index + 1}
        </Typography>
      )}

      <Typography
        variant="button"
        color="inherit"
        className={clsx(styles.text, isOptionalGreyOut && styles.textDisabled)}
      >
        {title}
        {isOptionalGreyOut && '*'}
      </Typography>
    </NavLink>
  );
};
