import { Dispatch } from 'react';

import { GetPartnerInfoAction } from 'common/api/actions/partner/partnerActions.types';
import { FormAction } from '../formReducer/formReducer.types';

export enum ReadyForRequestProcessingEnum {
  JunkRemoval = 'junkRemoval',
  Moving = 'moving',
  Labor = 'labor',
  CallMeNow = 'callMeNow',
}

export type UrlParamsUser = {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  postal?: string;
};

export type PartnerDataContextType = {
  partnerName: string;
  subpartnerId: number;
  subpartnerName: string;
  token: string;
  name: string;
  logoUrl: string;
  heroTitle: string;
  heroImg: string;
  discounts: GetPartnerInfoAction.Discount[];
  shouldDisplayPartnersFields: boolean;
  type: {
    id: number;
    name: string;
  };
};

export type FormStateContextType = {
  token?: string;
  locationId?: number;
  accountId?: number;
  zoneId?: number;
  isReadyToPing?: boolean;
  readyForRequestProcessing?: ReadyForRequestProcessingEnum;
  bookingDone?: boolean;
  contactData?: {
    type: 'email' | 'phone';
    contact: string;
  };
  appointmentId?: number; // B: for bMove
  isCallRequired?: boolean; // B: for bMove
  isPartner: boolean;
  partnerData?: PartnerDataContextType;
  account?: UrlParamsUser & { id?: number; locationId?: number };
};

export type FormDispatchContextType = Dispatch<FormAction>;
