import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { ContactInformationFormValues } from 'common/app/contactInformationForm/ContactInformationForm.types';
import { CategoryIdEnum } from 'common/api/types';
import { ContactInformationForm } from 'common/app/contactInformationForm/ContactInformationForm';
import { AppRoute, laborRoutes } from 'common/routing/AppRoute';
import { useLaborContext } from 'common/hooks/useLabor/useLabor';
import { laborActions } from 'common/context/labor/LaborContextContextSlice';
import { useAccount } from 'common/hooks/useAccount/useAccount';
import { useFormState } from 'common/hooks/useFormState/useFormState';
import { useCheckPostalCode } from 'common/hooks/useCheckPostalCode/useCheckPostalCode';
import { AccountResponse } from 'common/api/actions/account/accountActions.types';

export const LaborStep2 = () => {
  const history = useHistory();
  const [{ labor }, { laborDispatch }] = useLaborContext();
  const { createOrUpdateAccount, isCreatingOrUpdatingAccount, recreateAccountWithNewLocationId } = useAccount();
  const { account } = useFormState();
  const { checkPostalCode, isChecking } = useCheckPostalCode({
    category_id: CategoryIdEnum.labor,
    need_postal_info: true,
  });

  const handleSubmit = useCallback(
    async (values: ContactInformationFormValues, isDirty: boolean) => {
      if (!isDirty) {
        history.push(laborRoutes.step1);
        return;
      }

      const { address, nearestLocationId } = await checkPostalCode(values.postal);

      let accountRes: AccountResponse | undefined;
      const payload = {
        contactInformationData: values,
        billingAddress: address,
        categoryId: CategoryIdEnum.labor,
      };

      if (account?.locationId && account.locationId !== nearestLocationId) {
        accountRes = await recreateAccountWithNewLocationId(payload, nearestLocationId);
      } else {
        accountRes = await createOrUpdateAccount(payload, nearestLocationId);
      }

      if (!accountRes) {
        return;
      }

      laborDispatch(laborActions.setStep2(values));
      history.push(laborRoutes.step1);
    },
    [
      checkPostalCode,
      account?.locationId,
      laborDispatch,
      history,
      recreateAccountWithNewLocationId,
      createOrUpdateAccount,
    ],
  );

  return (
    <ContactInformationForm
      loading={isCreatingOrUpdatingAccount || isChecking}
      onSubmit={handleSubmit}
      title="1. Contact information"
      onBackClick={() => history.push(AppRoute.home)}
      defaultValues={{
        firstName: labor?.step2?.firstName || account?.firstName || '',
        lastName: labor?.step2?.lastName || account?.lastName || '',
        email: labor?.step2?.email || account?.email || '',
        phone: labor?.step2?.phone || account?.phone || '',
        postal: labor?.step2?.postal || account?.postal,
        companyName: labor?.step2?.companyName || '',
        orderNumber: labor?.step2?.orderNumber || '',
        specialInstructions: labor?.step2?.specialInstructions || '',
        additionalNotes: labor?.step2?.additionalNotes || '',
      }}
      hasPrefilledData={!!labor?.step2}
      tipBoxData={{
        icon: 'h',
        text: (
          <>
            Did you know? HUNKS stands for <strong>H</strong>onest, <strong>U</strong>niformed, <strong>N</strong>ice,{' '}
            <strong>K</strong>nowledgeable <strong>S</strong>ervice – our promise to you!
          </>
        ),
      }}
    />
  );
};
