import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { colors } from '@chhjpackages/components';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: colors.grey180,
      padding: '24px 8px',

      [theme.breakpoints.up('sm')]: {
        padding: '48px 0',
        textAlign: 'center',

        ...theme.typography.h2,
      },

      [theme.breakpoints.up('md')]: {
        paddingTop: 60,
      },
    },
    highlightText: {
      color: colors.secondary.main,
    },
    serviceBoxesContainer: {
      backgroundColor: colors.basic.white,
    },
    serviceBoxes: {
      paddingBottom: 16,

      '& > *': {
        marginBottom: 16,
      },

      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 48,

        '& > *': {
          marginBottom: 0,
          flexBasis: '31%',
        },
      },

      [theme.breakpoints.up('md')]: {
        '& > *': {
          flexBasis: '30.5%',
        },
      },
    },
  }),
);
