import React, { useCallback } from 'react';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { Container } from 'common/app/home/container/Container';
// import { CallMeNowModalContainer } from 'common/app/callMeNowModal/CallMeNowModalContainer';
import { useResetState } from 'common/hooks/useResetState/useResetState';
import { useFormState } from 'common/hooks/useFormState/useFormState';
import { serviceBoxesData } from 'common/utils/serviceBoxesData';

import { useStyles } from './Home.styles';
import { ServiceCard } from './serviceCard/ServiceCard';
import { AboutHunks } from './aboutHunks/AboutHunks';
import { WhyHunks } from './whyHunks/WhyHunks';
import { HappyCustomers } from './happyCustomers/HappyCustomers';
import { InTheCommunity } from './inTheCommunity/InTheCommunity';
import { PartnerHero } from './partnerHero/PartnerHero';

export const Home = () => {
  const styles = useStyles();
  const { location, push } = useHistory();
  const { isPartner, partnerData } = useFormState();
  const resetState = useResetState();

  const handleServiceClick = useCallback(
    (route: string) => {
      resetState();

      push(route);
    },
    [location.search, push, resetState],
  );

  return (
    <div data-testid="home">
      {isPartner && <PartnerHero />}

      <Container id="service-boxes" className={styles.serviceBoxesContainer}>
        <Typography variant="h3" className={styles.title}>
          {isPartner && 'Select your service below to get started:'}
          {!isPartner && (
            <>
              Select your service below and get a free estimate from{' '}
              <span className={styles.highlightText}>college H.U.N.K.S.</span>
            </>
          )}
        </Typography>

        <div className={styles.serviceBoxes}>
          {serviceBoxesData(partnerData?.partnerName).map(({ icon, title, subTitle, discount, route }) => (
            <ServiceCard
              key={route}
              icon={icon}
              title={title}
              subTitle={subTitle}
              discount={discount}
              onClick={() => handleServiceClick(route)}
            />
          ))}
        </div>
      </Container>

      {isPartner && (
        <>
          <WhyHunks />
          <AboutHunks />
          <HappyCustomers />
          <InTheCommunity />
        </>
      )}

      {/* TODO CALLMNOW: modal hidden for now */}
      {/* <CallMeNowModalContainer /> */}
    </div>
  );
};
