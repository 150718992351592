import React, { useState, useCallback, useEffect } from 'react';
import { Typography, FormControl, FormHelperText } from '@material-ui/core';
import { Button, Input } from '@chhjpackages/components';
import { useMutation } from 'react-fetching-library';
import { Redirect, useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

import { AddressAutocomplete } from 'common/ui/addressAutocomplete/AddressAutocomplete';
import { GoogleLocation } from 'common/ui/addressAutocomplete/AddressAutocomplete.types';
import { GoogleMaps } from 'common/ui/googleMaps/GoogleMaps';
import { BillingAddress } from 'common/api/actions/account/accountActions.types';
import { checkPostalCodeAction } from 'common/api/actions/booking/bookingActions';
import { CategoryIdEnum } from 'common/api/types';
import { junkRemovalRoutes } from 'common/routing/AppRoute';
import { TipBox } from 'common/ui/tipBox/TipBox';
import { ButtonsGroup } from 'common/ui/buttonsGroup/ButtonsGroup';
import { validateAddress } from 'common/utils/validations';
import { useAccount } from 'common/hooks/useAccount/useAccount';
import { useJunkRemovalContext } from 'common/hooks/useJunkRemoval/useJunkRemoval';
import { junkRemovalActions } from 'common/context/junkRemoval/JunkRemovalContextSlice';
import { useResetState } from 'common/hooks/useResetState/useResetState';
import { useComparePostalCode } from 'common/hooks/useComparePostalCode/useComparePostalCode';

import { useStyles } from './JunkRemovalStep1.styles';

export const JunkRemovalStep1 = () => {
  const styles = useStyles();
  const history = useHistory();
  const { mutate, loading } = useMutation(checkPostalCodeAction);
  const { isRecreatingAccount } = useAccount();
  const [{ junkRemoval }, { junkRemovalDispatch }] = useJunkRemovalContext();
  const resetState = useResetState();
  const [googleMapsLocation, setGoogleMapsLocation] = useState<GoogleLocation | undefined>(
    junkRemoval?.step1?.googleMapsLocation,
  );
  const { register, handleSubmit, control, setValue, errors, watch, formState } = useForm({
    defaultValues: {
      fullAddress: junkRemoval?.step1?.fullAddress || null,
      address: junkRemoval?.step1?.address || '',
      apartment: junkRemoval?.step1?.fullAddress.address2 || '',
    },
    mode: 'onBlur',
  });
  const { isDirty, isValid } = formState;

  useEffect(() => {
    register('address'); //field without physical input
  }, [register]);

  const shortAddress = watch('address');
  const fullAddress = watch('fullAddress');

  const { comparePostal } = useComparePostalCode({
    categoryId: CategoryIdEnum.junk,
    postal: junkRemoval.step2?.postal,
    onFail: (newPostal: string) => {
      if (junkRemoval.step2) {
        junkRemovalDispatch(
          junkRemovalActions.setStep2({
            ...junkRemoval.step2,
            postal: newPostal,
          }),
        );
        history.push(junkRemovalRoutes.step2);
      }
    },
  });

  useEffect(() => {
    if (comparePostal && fullAddress?.postal && fullAddress.postal !== '') {
      comparePostal(fullAddress.postal);
    }
  }, [fullAddress?.postal]);

  const handleSubmitCallback = useCallback(
    async ({
      fullAddress,
      address,
      apartment,
    }: {
      fullAddress: BillingAddress;
      address: string;
      apartment: string;
    }) => {
      if (!isDirty) {
        history.push(junkRemovalRoutes.step3);
        return;
      }

      const { payload } = await mutate({ postal: fullAddress.postal, job_category_id: CategoryIdEnum.junk });

      if (payload?.locations?.length) {
        const addressForApi = {
          ...fullAddress,
          address2: apartment,
        };

        if (googleMapsLocation) {
          junkRemovalDispatch(
            junkRemovalActions.setStep1({
              address,
              googleMapsLocation,
              fullAddress: addressForApi,
            }),
          );

          history.push(junkRemovalRoutes.step3);
        }
      } else {
        resetState();
        history.push(junkRemovalRoutes.unserviced);
        return;
      }
    },
    [googleMapsLocation, resetState, junkRemovalDispatch, history, mutate, isDirty],
  );

  if (!junkRemoval?.step2) {
    return <Redirect to={junkRemovalRoutes.step2} />;
  }

  return (
    <form onSubmit={handleSubmit(handleSubmitCallback)} className={styles.wrapper}>
      <div className={styles.content}>
        <Typography variant="h4">2. Your location</Typography>

        <Typography variant="body1">
          Letting us know where you need service helps us provide availability and pricing specific to your location.
        </Typography>

        <FormControl fullWidth error={!!errors.fullAddress}>
          <Controller
            control={control}
            rules={{
              validate: (fullAddr) => {
                const addressError = validateAddress(fullAddr);
                return addressError || undefined;
              },
            }}
            name="fullAddress"
            render={({ onChange, onBlur }) => {
              return (
                <AddressAutocomplete
                  label="Address"
                  initialValue={shortAddress}
                  onSelect={(values) => {
                    onChange(values?.fullAddress || null);
                    setValue('address', values?.inputValue || '');
                    if (values?.location) setGoogleMapsLocation(values?.location);
                    onBlur();
                  }}
                  hasError={!!errors.fullAddress}
                />
              );
            }}
          />
          <FormHelperText className={styles.errorText}>{errors.fullAddress?.message || '*required'}</FormHelperText>
        </FormControl>

        <FormControl fullWidth>
          <Input name="apartment" label="Apartment/suite" size="medium" variant="outlined" inputRef={register} />
        </FormControl>

        {googleMapsLocation && <GoogleMaps location={googleMapsLocation} />}

        <TipBox iconName="recycling" className={styles.tipBox}>
          We’ll do everything we can to give your items a second life. We donate or recycle <strong>up to 70%</strong>{' '}
          of the items we remove.
        </TipBox>
      </div>

      <ButtonsGroup>
        <Button onClick={() => history.push(junkRemovalRoutes.step2)} fullWidth size="large" buttonType="outlined">
          Back
        </Button>
        <Button
          type="submit"
          disabled={!isValid}
          isLoading={loading || isRecreatingAccount}
          fullWidth
          size="large"
          buttonType="twoTone"
        >
          Next
        </Button>
      </ButtonsGroup>
    </form>
  );
};
