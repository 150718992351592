import React, { useCallback, useEffect, useMemo } from 'react';
import { Box, Typography, Grid, FormControl, FormHelperText, Collapse } from '@material-ui/core';
import { Input, Button, TileToggle, NewSelect, SelectOptionType } from '@chhjpackages/components';
import { useForm, Controller } from 'react-hook-form';
import clsx from 'clsx';

import { AccessTypeEnum, TypeOfDwellingEnum } from 'common/api/types';
import { ButtonsGroup } from 'common/ui/buttonsGroup/ButtonsGroup';
import {
  accessTypeTiles,
  floorOptions,
  typeOfDwellingOptions,
  truckParkingOptions,
  bedroomsTiles,
} from 'bMove/app/moving/MovingData';
import { AddressAutocomplete } from 'common/ui/addressAutocomplete/AddressAutocomplete';
import { validateAddress } from 'common/utils/validations';
import { TipBox } from 'common/ui/tipBox/TipBox';

import { useStyles } from './MovingFormStep1Step2.styles';
import { MovingFormStep1Step2Props, MovingStep1Step2FormValues } from './MovingFormStep1Step2.types';

export const MovingFormStep1Step2 = ({
  withRooms,
  defaultValues,
  title,
  isLoading,
  tipBoxData,
  onSubmit,
  onBackClick,
  comparePostal,
}: MovingFormStep1Step2Props) => {
  const styles = useStyles();
  const { handleSubmit, register, errors, control, formState, watch, setValue } = useForm({
    mode: 'onBlur',
    defaultValues,
  });
  const { isDirty } = formState;

  useEffect(() => {
    register('shortAddress'); //field without physical input
  }, [register]);

  const shortAddress = watch('shortAddress');
  const floor = watch('floor');
  const typeOfDwelling = watch('typeOfDwelling');
  const fullAddress = watch('fullAddress');

  useEffect(() => {
    if (comparePostal && fullAddress?.postal && fullAddress.postal !== '') {
      comparePostal(fullAddress.postal);
    }
  }, [fullAddress?.postal]);

  const onSubmitCallback = useCallback(
    (values: MovingStep1Step2FormValues) => {
      onSubmit(values, isDirty);
    },
    [onSubmit, isDirty],
  );

  const renderAccessType = useMemo(() => floor !== 0, [floor]);
  const renderFloorAndAccessType = useMemo(() => typeOfDwelling !== TypeOfDwellingEnum.House, [typeOfDwelling]);

  const handleFloorChange = useCallback(
    (onChange: (val: unknown) => void) => (option: SelectOptionType<number> | null) => {
      const value = option ? option.value : null;

      if (value === 0) {
        setValue('accessType', AccessTypeEnum['Front door'], { shouldDirty: true });
      } else {
        setValue('accessType', null, { shouldDirty: true });
      }

      onChange(value);
    },
    [setValue],
  );

  const handleDwellingChange = useCallback(
    (onChange: (val: unknown) => void) => (option: SelectOptionType<number> | null) => {
      const value = option ? option.value : null;

      if (value === TypeOfDwellingEnum.House) {
        setValue('floor', null, { shouldDirty: true });
        setValue('accessType', AccessTypeEnum['Front door'], { shouldDirty: true });
      } else {
        setValue('accessType', null, { shouldDirty: true });
      }

      onChange(value);
    },
    [setValue],
  );

  return (
    <form onSubmit={handleSubmit(onSubmitCallback)} className={styles.form}>
      <Grid container direction="column" className={styles.content}>
        <Grid item>
          <Typography variant="h4">{title}</Typography>
        </Grid>

        <Box mb={3} />

        <Grid item>
          <Typography variant="body1">
            Your estimate is based on your exact location, so we need to know where you need help
          </Typography>
        </Grid>

        <Box mb={3} />

        <Grid item>
          <FormControl fullWidth error={!!errors.fullAddress}>
            <Controller
              control={control}
              rules={{
                validate: (fullAddr) => {
                  const addressError = validateAddress(fullAddr);
                  return addressError || undefined;
                },
              }}
              name="fullAddress"
              render={({ onChange, onBlur }) => {
                return (
                  <AddressAutocomplete
                    label="Address"
                    initialValue={shortAddress}
                    onSelect={(values) => {
                      onChange(values?.fullAddress || null);
                      setValue('shortAddress', values?.inputValue || '');
                      onBlur();
                    }}
                    hasError={!!errors?.fullAddress}
                  />
                );
              }}
            />

            <FormHelperText className={styles.inputError}>{errors.fullAddress?.message}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item>
          <FormControl fullWidth error={!!errors.apartment}>
            <Input
              name="apartment"
              label="Apartment/suite"
              size="medium"
              variant="outlined"
              error={!!errors.apartment}
              inputRef={register()}
            />
            <FormHelperText className={styles.inputError}>{errors.apartment?.message}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item>
          <FormControl fullWidth error={!!errors.typeOfDwelling}>
            <Controller
              name="typeOfDwelling"
              defaultValue={null}
              control={control}
              rules={{ required: '*required' }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <NewSelect<number>
                  isSearchable={false}
                  onChange={handleDwellingChange(onChange)}
                  onBlur={onBlur}
                  value={typeOfDwellingOptions.find((option) => option.value === value) ?? null}
                  inputRef={ref}
                  name={name}
                  error={!!errors.typeOfDwelling}
                  label="Type of dwelling"
                  options={typeOfDwellingOptions}
                />
              )}
            />

            <FormHelperText className={styles.inputError}>{errors.typeOfDwelling?.message}</FormHelperText>
          </FormControl>
        </Grid>

        <Collapse in={renderFloorAndAccessType}>
          <Grid item>
            <FormControl fullWidth error={!!errors.floor}>
              <Controller
                name="floor"
                defaultValue={null}
                control={control}
                rules={{ required: renderFloorAndAccessType && '*required' }}
                render={({ onChange, onBlur, value, name, ref }) => (
                  <NewSelect<number>
                    isSearchable={false}
                    onChange={handleFloorChange(onChange)}
                    onBlur={onBlur}
                    inputRef={ref}
                    name={name}
                    error={!!errors.floor}
                    label="Which floor?"
                    value={floorOptions.find((option) => option.value === value) ?? null}
                    options={floorOptions}
                  />
                )}
              />
              <FormHelperText className={styles.inputError}>{errors.floor?.message}</FormHelperText>
            </FormControl>
          </Grid>

          <Collapse in={renderAccessType}>
            <Box mt={1} />

            <Grid item>
              <Typography
                variant="subtitle1"
                className={clsx({ [styles.label]: true, [styles.labelError]: errors.accessType })}
              >
                Access type
              </Typography>
              <FormControl fullWidth error={!!errors.accessType}>
                <Controller
                  name="accessType"
                  control={control}
                  rules={{ required: renderFloorAndAccessType && renderAccessType && '*required' }}
                  render={({ onChange, onBlur, value }) => (
                    <TileToggle<number>
                      onChange={(val) => {
                        onChange(val);
                        onBlur();
                      }}
                      value={value}
                      onBlur={onBlur}
                      classes={{ tile: styles.tile }}
                      spacing={3}
                      md={5}
                      tiles={accessTypeTiles}
                    />
                  )}
                />

                <FormHelperText className={styles.inputError}>{errors.accessType?.message}</FormHelperText>
              </FormControl>
            </Grid>
          </Collapse>
        </Collapse>

        <Grid item>
          <FormControl fullWidth error={!!errors.truckParking}>
            <Controller
              name="truckParking"
              control={control}
              rules={{ required: '*required' }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <NewSelect<number>
                  isSearchable={false}
                  onChange={(option: SelectOptionType<number> | null) => onChange(option?.value ?? null)}
                  onBlur={onBlur}
                  inputRef={ref}
                  name={name}
                  value={truckParkingOptions.find((option) => option.value === value) ?? null}
                  error={!!errors.truckParking}
                  label="Where should we park the truck?"
                  options={truckParkingOptions}
                />
              )}
            />

            <FormHelperText className={styles.inputError}>{errors.truckParking?.message}</FormHelperText>
          </FormControl>
        </Grid>

        {withRooms && (
          <Grid item>
            <Typography variant="subtitle1" className={clsx([styles.label, errors.rooms && styles.labelError])}>
              Number of bedrooms:
            </Typography>
            <FormControl fullWidth error={!!errors.rooms}>
              <Controller
                name="rooms"
                control={control}
                rules={{ required: '*required' }}
                render={({ onChange, onBlur, value }) => (
                  <TileToggle<string>
                    onChange={(val) => {
                      onChange(val);
                      onBlur();
                    }}
                    value={value}
                    classes={{ tile: styles.tile }}
                    spacing={3}
                    md={5}
                    tiles={bedroomsTiles}
                  />
                )}
              />

              <FormHelperText className={styles.inputError}>{errors.rooms?.message}</FormHelperText>
            </FormControl>
          </Grid>
        )}
      </Grid>

      <Box mt="auto">
        <TipBox iconName={tipBoxData.icon} className={styles.tipbox}>
          {tipBoxData.text}
        </TipBox>

        <ButtonsGroup>
          <Button onClick={onBackClick} fullWidth size="large" buttonType="outlined">
            Back
          </Button>

          <Button type="submit" isLoading={isLoading} fullWidth size="large" buttonType="twoTone">
            Next
          </Button>
        </ButtonsGroup>
      </Box>
    </form>
  );
};
