import React, { useCallback, useEffect, useState } from 'react';
import { Box, Typography, Grid } from '@material-ui/core';
import { Button, CalendarPicker } from '@chhjpackages/components';
import { useForm, Controller } from 'react-hook-form';
import { Redirect, useHistory } from 'react-router-dom';
import moment, { Moment } from 'moment';

import { ButtonsGroup } from 'common/ui/buttonsGroup/ButtonsGroup';
import { TipBox } from 'common/ui/tipBox/TipBox';
import { movingRoutes } from 'bMove/routing/AppRoute';
import { useMovingContext } from 'bMove/hooks/useMoving/useMoving';
import { movingActions } from 'bMove/context/moving/MovingContextSlice';
import { useFormState } from 'common/hooks/useFormState/useFormState';

import { useStyles } from './MovingStep4.styles';

export const MovingStep4 = () => {
  const styles = useStyles();
  const history = useHistory();
  const [{ moving, isCalculatingQuote }, { movingDispatch }] = useMovingContext();
  const { handleSubmit, control } = useForm({
    defaultValues: { date: moment(moving?.step4?.date) },
  });
  const { isCallRequired } = useFormState();

  const [isWaitingForQuote, setWaitingForQuote] = useState(false);

  const redirectToNextStep = useCallback(() => {
    if (isCallRequired) {
      history.push(movingRoutes.step8);
    } else {
      history.push(movingRoutes.step6);
    }
  }, [history, isCallRequired]);

  const handleSubmitCallback = useCallback(
    (values: { date: Moment }) => {
      movingDispatch(movingActions.setStep4({ date: values.date.toDate() }));

      /*
      createAccount will createOrUpdateAccount and save accountId & token to state,
      after that useEffect (in Controller) is runned for calculatingQuote,
      then i set waiting state for that quote to check if callIsRequired
      */
      setWaitingForQuote(true);
    },
    [movingDispatch],
  );

  useEffect(() => {
    if (!isWaitingForQuote || isCallRequired === undefined || (isCallRequired !== undefined && isCalculatingQuote))
      return;

    // if isCallRequired it NOT undefined, it means that quote has been fetched and it's ready to proceed
    setWaitingForQuote(false);
    redirectToNextStep();
  }, [redirectToNextStep, isWaitingForQuote, isCallRequired, isCalculatingQuote]);

  if (!moving?.step3) {
    return <Redirect to={movingRoutes.step3} />;
  }

  return (
    <form onSubmit={handleSubmit(handleSubmitCallback)} className={styles.form}>
      <Grid container direction="column" className={styles.content}>
        <Grid item>
          <Typography variant="h4">5. job date</Typography>
        </Grid>

        <Box mb={3} />

        <Grid item>
          <Typography variant="body1">Select the day that works best for you for our HUNKS to arrive</Typography>
        </Grid>

        <Grid item>
          <Controller
            name="date"
            control={control}
            rules={{ required: '*required' }}
            render={({ onChange, value }) => (
              <CalendarPicker
                onChange={onChange}
                date={value}
                minDate={moment().toDate()}
                className={styles.calendar}
              />
            )}
          />
        </Grid>
      </Grid>

      <TipBox iconName="labor">No job is too big or too small for College HUNKS Hauling Junk & Moving.</TipBox>

      <ButtonsGroup>
        <Button onClick={() => history.push(movingRoutes.step3)} fullWidth size="large" buttonType="outlined">
          Back
        </Button>

        <Button
          type="submit"
          fullWidth
          size="large"
          buttonType="twoTone"
          isLoading={isCalculatingQuote || isWaitingForQuote}
        >
          Next
        </Button>
      </ButtonsGroup>
    </form>
  );
};
