import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { createGenerateClassName, StylesProvider } from '@material-ui/core';
import {
  CollegeHunksTheme,
  CalendarPickerProvider,
  AlertProvider,
  DialogContextController,
} from '@chhjpackages/components';

import { ClientContextController } from 'common/context/client/clientContextController/ClientContextController';
import { FormContextController } from 'common/context/form/formContextController/FormContextController';
import { ErrorBoundary } from 'common/app/errorBoundary/ErrorBoundary';
import { PingController } from 'common/ui/pingController/PingController';
import { GoogleScriptsController } from 'common/ui/googleScriptsController/GoogleScriptsController';
import { JunkRemovalContextController } from 'common/context/junkRemoval/JunkRemovalContextController';
import { LaborContextController } from 'common/context/labor/LaborContextContextController';
import { VersionContextController } from 'common/context/version/VersionContextController';

import { AppProvidersProps } from './AppProviders.types';
import { MovingProviderController } from './MovingProviderController';

const generateClassName = createGenerateClassName({
  productionPrefix: 'app',
});

export const AppProviders = ({ children }: AppProvidersProps) => (
  <StylesProvider generateClassName={generateClassName}>
    <ErrorBoundary>
      <CollegeHunksTheme>
        <CalendarPickerProvider>
          <AlertProvider maxSnack={3} anchorOrigin={{ horizontal: 'right', vertical: 'top' }} preventDuplicate>
            <DialogContextController>
              <GoogleScriptsController>
                <ClientContextController>
                  <Router>
                    <FormContextController>
                      <VersionContextController>
                        <JunkRemovalContextController>
                          <LaborContextController>
                            <MovingProviderController>
                              <PingController>{children}</PingController>
                            </MovingProviderController>
                          </LaborContextController>
                        </JunkRemovalContextController>
                      </VersionContextController>
                    </FormContextController>
                  </Router>
                </ClientContextController>
              </GoogleScriptsController>
            </DialogContextController>
          </AlertProvider>
        </CalendarPickerProvider>
      </CollegeHunksTheme>
    </ErrorBoundary>
  </StylesProvider>
);
