import React, { useCallback, useState, useRef } from 'react';
import { Typography, Box } from '@material-ui/core';
import { Button } from '@chhjpackages/components';
import { useHistory, Redirect } from 'react-router-dom';

import { BedroomIcon } from 'common/assets/icons/BedroomIcon';
import { FewItemsIcon } from 'common/assets/icons/FewItemsIcon';
import { RoomOfItemsIcon } from 'common/assets/icons/RoomOfItemsIcon';
import { HouseCleanupIcon } from 'common/assets/icons/HouseCleanupIcon';
import { junkRemovalRoutes } from 'common/routing/AppRoute';
import { ButtonsGroup } from 'common/ui/buttonsGroup/ButtonsGroup';
import { TipBox } from 'common/ui/tipBox/TipBox';
import { useJunkRemovalContext } from 'common/hooks/useJunkRemoval/useJunkRemoval';
import { junkRemovalActions } from 'common/context/junkRemoval/JunkRemovalContextSlice';

import { useStyles } from './JunkRemovalStep4.styles';
import { JunkRemovalStep4Card } from './JunkRemovalStep4Card/JunkRemovalStep4Card';

const serviceBoxesData = [
  {
    icon: <BedroomIcon />,
    title: 'Single item',
  },
  {
    icon: <FewItemsIcon />,
    title: 'A few items',
  },
  {
    icon: <RoomOfItemsIcon />,
    title: 'Room full of items',
  },
  {
    icon: <HouseCleanupIcon />,
    title: 'Full house / office cleanup',
  },
];

export const JunkRemovalStep4 = () => {
  const styles = useStyles();
  const history = useHistory();
  const [{ junkRemoval }, { junkRemovalDispatch }] = useJunkRemovalContext();
  const bookBtnRef = useRef<HTMLButtonElement | null>(null);
  const [selectedCardIndex, setSelectedCardIndex] = useState<number | null>(
    junkRemoval?.step4?.selectedCardIndex ?? null,
  );

  const handleNextStep = useCallback(() => {
    if (selectedCardIndex === null) return;

    junkRemovalDispatch(
      junkRemovalActions.setStep4({
        selectedCardIndex,
        title: serviceBoxesData[selectedCardIndex].title,
      }),
    );

    history.push(junkRemovalRoutes.step5);
  }, [junkRemovalDispatch, history, selectedCardIndex]);

  const handleCardSelection = useCallback(
    (cardIndex: number) => {
      if (cardIndex === selectedCardIndex) {
        setSelectedCardIndex(null);
      } else {
        setSelectedCardIndex(cardIndex);
        bookBtnRef.current?.scrollIntoView({ behavior: 'smooth' });
      }
    },
    [selectedCardIndex],
  );

  if (!junkRemoval.step4 && !junkRemoval?.step3) {
    return <Redirect to={junkRemovalRoutes.step3} />;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <Typography variant="h4">4. Number of items</Typography>

        <Box mt={2} />
        <Typography variant="body1">
          <strong>Estimate the size</strong> of the items you would like to be removed. We will give you a personalized
          estimate once we arrive on-site.
        </Typography>

        <Box mt={2} />
        <div className={styles.boxesContainer}>
          {serviceBoxesData.map(({ title, icon }, index) => (
            <JunkRemovalStep4Card
              key={title}
              title={title}
              icon={icon}
              isSelected={index === selectedCardIndex}
              onClick={() => handleCardSelection(index)}
            />
          ))}
        </div>

        <TipBox iconName="ecoFriendly">
          Our eco-friendly cleanup experts will remove all non-hazardous items from{' '}
          <strong>anywhere on your property</strong>, and properly dispose of all unwanted clutter.
        </TipBox>
      </div>

      <ButtonsGroup>
        <Button onClick={() => history.push(junkRemovalRoutes.step3)} fullWidth size="large" buttonType="outlined">
          Back
        </Button>
        <Button
          onClick={handleNextStep}
          disabled={selectedCardIndex === null}
          fullWidth
          size="large"
          buttonType="twoTone"
          ref={bookBtnRef}
        >
          Next
        </Button>
      </ButtonsGroup>
    </div>
  );
};
