import { movingRoutes as movingVerBRoutes } from 'bMove/routing/AppRoute';
import { junkRemovalRoutes, laborRoutes, movingRoutes } from 'common/routing/AppRoute';
import { AsideNavbarData } from 'common/ui/asideNavbar/AsideNavbar.types';

// key "step" must be the same as formState.junkRemoval or formState.moving or formState.labor KEYS
export const asideNavbarData: AsideNavbarData = {
  junkRemoval: [
    {
      id: 2,
      step: 'step2',
      title: 'contact information',
      route: junkRemovalRoutes.step2,
    },
    {
      id: 1,
      step: 'step1',
      title: 'your location',
      route: junkRemovalRoutes.step1,
    },
    {
      id: 3,
      step: 'step3',
      title: 'job date',
      route: junkRemovalRoutes.step3,
    },
    {
      id: 4,
      step: 'step4',
      title: 'number of items',
      route: junkRemovalRoutes.step4,
    },
    {
      id: 5,
      step: 'step5',
      title: 'pricing',
      route: junkRemovalRoutes.step5,
    },
    // TODO SUMMARY: commented summary step in junk removal for now
    // {
    //   id: 6,
    //   step: 'step6',
    //   title: 'summary',
    //   route: junkRemovalRoutes.step6,
    // },
  ],
  moving: [
    {
      id: 5,
      step: 'step5',
      title: 'contact information',
      route: movingRoutes.step5,
    },
    {
      id: 1,
      step: 'step1',
      title: 'moving from',
      route: movingRoutes.step1,
    },
    {
      id: 2,
      step: 'step2',
      title: 'moving to',
      route: movingRoutes.step2,
    },
    {
      id: 3,
      step: 'step3',
      title: 'special items',
      route: movingRoutes.step3,
    },
    {
      id: 4,
      step: 'step4',
      title: 'job date',
      route: movingRoutes.step4,
    },
    {
      id: 6,
      step: 'step6',
      title: 'summary',
      route: movingRoutes.step6,
    },
  ],
  labor: [
    {
      id: 2,
      step: 'step2',
      title: 'contact information',
      route: laborRoutes.step2,
    },
    {
      id: 1,
      step: 'step1',
      title: 'your location',
      route: laborRoutes.step1,
    },
    {
      id: 3,
      step: 'step3',
      title: 'summary',
      route: laborRoutes.step3,
    },
  ],
};

export const asideNavbarVerBData: AsideNavbarData = {
  junkRemoval: [
    {
      id: 2,
      step: 'step2',
      title: 'contact information',
      route: junkRemovalRoutes.step2,
    },
    {
      id: 1,
      step: 'step1',
      title: 'your location',
      route: junkRemovalRoutes.step1,
    },
    {
      id: 3,
      step: 'step3',
      title: 'job date',
      route: junkRemovalRoutes.step3,
    },
    {
      id: 4,
      step: 'step4',
      title: 'number of items',
      route: junkRemovalRoutes.step4,
    },
    {
      id: 5,
      step: 'step5',
      title: 'pricing',
      route: junkRemovalRoutes.step5,
    },
    // TODO SUMMARY: commented summary step in junk removal for now
    // {
    //   id: 6,
    //   step: 'step6',
    //   title: 'summary',
    //   route: junkRemovalRoutes.step6,
    // },
  ],
  moving: [
    {
      id: 5,
      step: 'step5',
      title: 'contact information',
      route: movingVerBRoutes.step5,
    },
    {
      id: 1,
      step: 'step1',
      title: 'moving from',
      route: movingVerBRoutes.step1,
    },
    {
      id: 2,
      step: 'step2',
      title: 'moving to',
      route: movingVerBRoutes.step2,
    },
    {
      id: 3,
      step: 'step3',
      title: 'special items',
      route: movingVerBRoutes.step3,
    },
    {
      id: 4,
      step: 'step4',
      title: 'job date',
      route: movingVerBRoutes.step4,
    },
    {
      id: 6,
      step: 'step6',
      title: 'job time',
      route: movingVerBRoutes.step6,
      isOptional: true,
    },
    {
      id: 7,
      step: 'step7',
      title: 'pricing',
      route: movingVerBRoutes.step7,
      isOptional: true,
    },
    {
      id: 8,
      step: 'step8',
      title: 'summary',
      route: movingVerBRoutes.step8,
    },
  ],
  labor: [
    {
      id: 2,
      step: 'step2',
      title: 'contact information',
      route: laborRoutes.step2,
    },
    {
      id: 1,
      step: 'step1',
      title: 'your location',
      route: laborRoutes.step1,
    },
    {
      id: 3,
      step: 'step3',
      title: 'summary',
      route: laborRoutes.step3,
    },
  ],
};
